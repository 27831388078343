import React from 'react'

function EndQuote({ className }) {
  return (
    <>
      <section className={`appie-project-3-area ${className} `}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="
                appie-project-3-box
                d-block d-md-flex
                justify-content-between
                align-items-center
                wow
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <h4 className="title" style={{ fontSize: '38px' }}>
                  Ta del av våra generösa bonusar <br />
                  när vi lanserar!
                </h4>
                <a className="main-btn" href="#">
                  Skriv upp mig
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EndQuote
